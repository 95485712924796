import React, { useEffect } from "react";
import Header from "../components/header.component";
import { Outlet, useLoaderData } from "react-router-dom";


const Layout = ({user}) => {
    return (
        <>
            <Header user={user} />
            <Outlet/>
        </>
    )

}

export default Layout;