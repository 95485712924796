import React, { useEffect, useState } from "react";
import Search from "../components/search.component";
import Chart from 'chart.js/auto';
import { useNavigate } from "react-router-dom";


const data = [
    {
        title: "9S Pro Smartphone 5G, 120Hz Gaming Phone, 6.8\" Full Screen, ...",
        img: "https://m.media-amazon.com/images/I/61mRfNRY7TL._AC_UY218_.jpg",
        price: 790,
        website: "Amazon"
    },
    {
        title: "A11 Unlocked Cell Phones, 6.53\" HD+ Full Screen Smartphone, ...",
        img: "https://m.media-amazon.com/images/I/611OMQHZzBL._AC_UY218_.jpg",
        price: 99,
        website: "Amazon"
    },
    {
        title: "Zenfone 11 Ultra AI Smartphone, Android Unlocked, 12GB+256GB, ...",
        img: "https://m.media-amazon.com/images/I/71EXwC9joTL._AC_UY218_.jpg",
        price: 749,
        website: "Amazon"
    },

]
const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
const datasets = [
    {
        data: [820, 800 ,790, 820, 830 ,790, 820, 700 ,790 ,790],
        label: "9S Pro Smartphone 5G ...",
        borderColor: "#3e95cd",
        fill: false,
        segment: {
            borderDash: ctx => skipped(ctx, [6, 6]),
        },
        spanGaps: true,
    },
    {
        data: [210, 200 ,200, 100, 200 , 150, 130, 120 ,100 ,99],
        label: "A11 Unlocked Cell Phones ...",
        borderColor: "#ff4081",
        fill: false,
        segment: {
            borderDash: ctx => skipped(ctx, [6, 6]),
        },
        spanGaps: true,
    },
    {
        data: [780, 810 ,800, 790, 850 , 820, 810, 800 ,780 ,749],
        label: "Zenfone 11 Ultra AI ...",
        borderColor: "#004d40",
        fill: false,
        segment: {
            borderDash: ctx => skipped(ctx, [6, 6]),
        },
        spanGaps: true,
    }

]
let chart;


const renderChart = (labels, datasets) => {

    if (chart != null) chart.destroy();
    const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
    const config = {
        type: 'line',
        data: {
            labels: labels,
            datasets: datasets
        },
        options: {

            responsive: true,
            title: {
                display: true,
                text: 'World population per region (in millions)'
            },
            scales: {
                x: {
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: true
                    },
                },
                y: { grid: { display: true } }
            }
        }
    };

    const lineChart = document.getElementById("line-chart");
    chart = new Chart(lineChart, config);
    chart.update();
}
const HomePage = () => {
    const [query, setQuery] = useState();

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/search?query=${query}`);
    };


    const [products, setProducts] = useState([]);



    useEffect(() => {

        const arrIndexes = Array.from({ length: 10}, (v, i) => i).reverse();
        let labels = [];
        const optionsDate = { month: 'short', day: '2-digit' };
        arrIndexes.forEach(ele => {
            let previousDate = new Date();
            previousDate.setDate(previousDate.getDate() - ele);
            let formattedDate = previousDate.toLocaleDateString('en-US', optionsDate);
            labels.push(formattedDate);

        });
        setProducts(data);
        renderChart(labels, datasets)
        // const lineChart = document.getElementById("line-chart");    
        
    }, []);

    return (
        <div className="container" style={{ "marginTop": "120px" }}>

            {/* 
                Hero section
                H1                            | image
                p explain about the buisness  |
                why chose us , unique         |
                call an action
                --------------------------------------
                H2 exaplin mor about the tool
                --------------------------------------

                Features
                1. search find cheeps by prices accros multiple platform  (comparing by prices)
                cta (call to action) button ??
                2. Track product 

                --------------------------------------
                Listen to What Others Have to Say About us

                --------------------------------------
                most searched products
                --------------------------------------
                Get Strated Today
                --------------------------------------
                Footer
                
            */}
            <div className="row d-flex justify-content-center">
                <div className="col-lg-7 col-md-12 order-md-2 order-lg-1 order-sm-1 mt-3">

                    <h1 style={{ fontSize: "2.9rem" }}>Discover the Best Deals Across All Platforms</h1>

                    <p className="mt-4" style={{ fontSize: "1.4rem", lineHeight: "1.7" }}>
                        Find your desired products at the best prices, all in one place! <br />
                        Get real-time price tracking and never miss out on a great deal.
                    </p>

                    <div className="mt-4">
                        {/* <form > */}
                        <div className='row'>
                            <div className="col-lg-8 col-md-9 col-sm-10 col-9" style={{}}>
                                <div className="input-group input-group-sm">
                                    <span className="input-group-text" id="basic-addon1" style={{ "backgroundColor": "white" }}>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="What are you looking for?"
                                        aria-label="query"
                                        name="query"
                                        
                                        style={{ "borderLeft": "none" }}
                                        onChange={e => setQuery(e.target.value)}
                                    //onChange={formik.handleChange}
                                    //onBlur={formik.handleBlur}
                                    //value={formik.values.query}
                                    />
                                </div>

                                <div style={{ "position": "absolute", "left": "0", "padding": "0 8px", "width": "100%" }} className='mt-2 sugestion-search sugestion-search-toggle'>
                                    <div className='container-sugestion-search'>
                                        <ul>
                                            <li> <span>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam </span> </li>
                                            <li> <span>  molestias, corporis, optio ipsam minus quo sint velit cupiditate </span> </li>
                                            <li> <span> ad qui veniam illum ipsum aliquid voluptas excepturi dolore </span> </li>
                                            <li> <span> temporibus. Repellat, assumenda!</span> </li>
                                            <li> <span> temporibus. Repellat, assumenda!</span> </li>
                                            <li> <span> temporibus. Repellat, assumenda!</span> </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-3 col-sm-2 col-2">
                                <button type="submit" onClick={handleClick} className="btn btn-primary btn-sm" style={{ "backgroundColor": 'red !important' }} >search</button>
                            </div>
                        </div>
                        {/* </form> */}
                    </div>
                </div>
                <div className="col-lg-5 col-md-12 order-md-first  mt-3" style={{ backgroundColor: "" }}>
                    <img src="/images/section-hero-2.png" className="" width="auto" height="280px" alt="" />
                </div>
            </div>

            <div className="d-flex justify-content-center">
                <h3 style={{ fontSize: "1.6rem", color: "#0d47a1", borderBottom: "3px solid #0d47a1", padding: "8px" }}>Features</h3>
            </div>

            <div className="row d-flex justify-content-center align-items-center" style={{ marginTop: "80px" }}>

                <div className="col-lg-5 order-sm-1 mb-5 ">

                    <h2 style={{ fontSize: "2.0rem" }}> Search Products Across Multiple Platforms </h2>
                    <p className="mt-4" style={{ fontSize: "1.2rem", lineHeight: "1.6" }}>
                        Easily search and compare products from leading online marketplaces, all in one place. <br />
                        Our advanced product search engine allows you to find the best deals, compare prices, and read reviews across multiple platforms.<br />
                        Save time and money with our comprehensive tool that brings together the top shopping sites for a seamless online shopping experience.
                    </p>
                </div>

                <div className="col-lg-7 order-sm-2 order-xs-3 mt-sm-4">
                    {products.map((product, index) => {
                        return (
                            <div key={index}>
                                <div className="d-sm-none d-md-block d-none d-sm-block">
                                    <div className={`row p-2 mb-3  ${index == 1 ? 'shadow' : 'shadow-sm'}`} style={{ border: "2px solid #eeeeee", borderRadius: "3.5px" }}>
                                        <div className="col-lg-10 col-md-10" style={{ height: "inherit" }}>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-2">
                                                    <div className="d-flex justify-content-center">
                                                        <img src={product.img} alt="" className="responsive-img" />
                                                    </div>
                                                    {/* style={{ width: "auto", height: "100%", }} */}
                                                </div>
                                                <div className="col-lg-9 col-md-10">
                                                    <div className="d-flex flex-column justify-content-between" style={{ "height": "100%" }}>
                                                        <div>
                                                            {/* 9S Pro Smartphone 5G, 120Hz Gaming Phone, 6.8" Full Screen, Under Display Camera, 6500mAh Android Phone, Snapdragon 8 Gen 3, 12+256GB, 80W Charger, Dual-Sim, US Unlocked Cell Phone Black */}
                                                            <h1 className="truncate-multi-line" style={{ fontSize: "1.2rem", lineHeight: "1.5", fontWeight: "500" }}>
                                                                {product.title}
                                                            </h1>

                                                        </div>
                                                        <div>
                                                            <span className="fs-5 fw-semibold mt-3" style={{ "color": "#757575", fontWeight: "bold" }}><span>$</span> {product.price} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2" style={{ height: "inherit", borderLeft: "1px solid #e0e0e0" }}>
                                            {/* style={{ backgroundColor: "red"}} d-flex flex-column justify-content-between*/}
                                            <div className="d-flex flex-column justify-content-between" style={{ height: "100%" }}>
                                                <div >
                                                    <p style={{ fontSize: "1.09rem", fontWeight: "500", cursor: "pointer" }}>Amazon</p>
                                                </div>
                                                <div className="">
                                                    <i className="fa-solid fa-arrows-turn-right float-end" style={{ fontSize: "1.4rem", color: "#1e88e5", cursor: "pointer" }}></i>
                                                    <i className="fa-solid fa-arrow-trend-up" style={{ fontSize: "1.4rem", color: "#1b5e20", cursor: "pointer" }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-block d-sm-block d-md-none" style={{ padding: "0px 30px" }}>
                                    <div className="row p-2 shadow-sm mb-3" style={{border: "2px solid #eeeeee", borderRadius: "3.5px" }}>
                                        <div className="col-sm-12" style={{ height: "inherit" }}>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <p style={{ fontSize: "1.09rem", fontWeight: "bold", cursor: "pointer" }}>Amazon</p>
                                                </div>
                                                <div className="col-lg-3 col-md-2">
                                                    <div className="d-flex justify-content-center">
                                                        {/* responsive-img */}
                                                        <img src={product.img} alt="" className="" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 mt-4">
                                                    <div className="d-flex flex-column justify-content-between" style={{ "height": "100%" }}>
                                                        <div>
                                                            <h1 className="truncate-multi-line" style={{ fontSize: "1.2rem", lineHeight: "1.5", fontWeight: "500" }}>
                                                                {product.title}
                                                            </h1>

                                                        </div>
                                                        <div>
                                                            <span className="fs-5 fw-semibold mt-3" style={{ "color": "#757575", fontWeight: "bold" }}><span>$</span> {product.price} </span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-sm-12 mt-4" style={{ height: "inherit", borderLeft: "1px solid #e0e0e0" }}>
                                            <div className="d-flex flex-column justify-content-between" style={{ height: "100%" }}>
                                                {/* <div >
                                                    <p style={{ fontSize: "1.09rem", fontWeight: "500", cursor: "pointer" }}>Amazon</p>
                                                </div> */}
                                                <div className="">
                                                    <i className="fa-solid fa-arrows-turn-right float-end" style={{ fontSize: "1.4rem", color: "#1e88e5", cursor: "pointer" }}></i>
                                                    <i className="fa-solid fa-arrow-trend-up" style={{ fontSize: "1.4rem", color: "#1b5e20", cursor: "pointer" }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>




                        )
                    })}
                </div>



            </div>


            <div className="row d-flex justify-content-center align-items-center" style={{ marginTop: "80px" }}>
                <div className="col-xs-12 col-sm-12 col-lg-5 mb-5">
                    <h2 style={{ fontSize: "2.0rem" }}>Track Product Prices </h2>
                    <p className="mt-4" style={{ fontSize: "1.2rem", lineHeight: "1.6" }}>
                        Stay informed with our powerful price tracking feature that monitors product prices across various platforms like Amazon and Walmart.  <br />
                        Get instant alerts when prices drop or when exclusive deals become available. Our price tracker ensures you always pay the lowest price,  <br />
                        helping you save money on your favorite products by keeping you updated on the latest discounts and offers.
                    </p>
                </div>

                <div className="col-xs-12 col-sm-12 col-lg-7 ">
                    <canvas id="line-chart" ></canvas>
                </div>
            </div>


        </div>
    );
}

export default HomePage;