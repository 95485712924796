import React, { useEffect, useState } from 'react';
import Prodcut from '../components/product.component';
import Search from '../components/search.component';
// import Toast from '../components/toast.component';
import ProductService from '../services/product.service';
import Pagination from '../components/pagination.component';

import toast, { Toaster } from 'react-hot-toast';
import Toast from '../components/toast.component';
import { useSearchParams } from 'react-router-dom';


const ProductSearch = ({ user }) => {

    const notify = (title, message, type) => {
        toast.custom(
            <Toast title={title} message={message} type={type} />, {
            duration: 4000,
        }
        );
    }

    const [searchParams, setSearchParams] = useSearchParams();
    const [totalProductFound, setTotalProductFound] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(10);

    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState(null);

    const [result, setResult] = useState(null);
    const [loader, setLoader] = useState(false);

    // contains products 
    const handleChangeKeyword = (value) => setResult(value);

    const updateLoader = (value) => setLoader(value);
    const handlePagination = (pageNumber) => { setCurrentPage(pageNumber); };

    useEffect(() => {
        const query = searchParams.get('query');
        if (query != null && query != "") {
            setLoader(true);
        }
    }, []);

    useEffect(() => {
        //console.log(currentPage);        
        const totalNbpage = Math.ceil(totalProductFound / productsPerPage);
        const isCurrentPageValid = (currentPage <= totalNbpage && currentPage > 0);
        if (products.length != 0 && isCurrentPageValid) {
            const resultFindProductByPage = ProductService.findAllByPage(currentPage, productsPerPage, search?.id);
            resultFindProductByPage
                .then(response => response.data)
                .then(data => setProducts(data));
            setLoader(false);
        }

        if (products.length != 0 && !isCurrentPageValid) {
            if (currentPage == 0) setCurrentPage(1);
            if (currentPage > totalNbpage) setCurrentPage(totalNbpage);

        }

    }, [currentPage]);


    useEffect(() => {
        const response = result?.data;
        if (response != null || response != undefined) {
            const data = response?.resultSearch;
            if (response.status === 'SEARCH_LIMIT_EXCEEDED_PER_DAY') {
                setLoader(false);
                const message = "You've made the maximum number of requests allowed for today. Sign in to get additional access, or come back tomorrow!";
                const title = "Friendly Reminder";
                notify(title, message, "WARNING");
            }

            if (response.status === 'SUCCESS') {
                setProducts(response.resultSearch);
                setSearch(response.search);
                setTotalProductFound(response.totalProductFound);
                setLoader(false);
            }
        }

    }, [result]);


    return (
        <div className="container" style={{ "marginTop": "120px" }}>
            <Toaster />
            <div className="row d-flex justify-content-center" style={{}}>
                <div className="col-lg-6">
                    <Search
                        onChangeKeyword={handleChangeKeyword}
                        loader={updateLoader}
                    />
                </div>
            </div>

            {
                loader === true &&
                <div className='row d-flex justify-content-center mt-5' >
                    <div className="spinner-border text-info" role="status">
                        <span className="visually-hidden" >Loading...</span>
                    </div>
                </div>
            }

            <div className='row d-flex justify-content-center' >

                <div className='d-flex justify-content-center mb-5 mt-4'>
                    {totalProductFound !== null && <p className="fs-4" >Results show : <span className='fw-semibold'>{totalProductFound} option found</span> </p>}
                </div>

                {products.map((product, index) => {
                    return <div className='row d-flex justify-content-center' key={index}>
                        <Prodcut key={index} user={user} product={product} />
                    </div>
                })}

                {products.length != 0 &&
                    <Pagination
                        length={totalProductFound}
                        itemPerPage={productsPerPage}
                        currentPage={currentPage}
                        handlePagination={handlePagination}
                    />
                }

            </div>

        </div>
    )
}

export default ProductSearch;