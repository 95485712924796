import axios from "axios";
import AuthProviderService from "./authProvider.service";

const API_URL = `${process.env.REACT_APP_API_ENDPOINT}/api/product`;

const findAllByPage = async (page,sizePerPage,searchId) => {
    return axios.get(`${API_URL}/find/${searchId}?page=${page}&sizePerPage=${sizePerPage}`);
}


const ProductService = {
    findAllByPage,
}

export default ProductService;