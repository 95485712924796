import React, { useEffect } from 'react';
import { BrowserRouter, Link, useNavigate } from 'react-router-dom';
//import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
//import classNames from 'classnames';

import * as bootstrap from 'bootstrap';
import UserService from '../services/user.service';

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]

const showDropdown = (e) => {
  if (e.target != null && e.target != undefined) {
    const dropdown = new bootstrap.Dropdown(e.target);
    dropdown.show()
  }
}

const Header = ({ user }) => {

  const navigate = useNavigate();

  useEffect(() => {
    const offcanvasElementList = document.querySelectorAll('.offcanvas')
    const offcanvasList = [...offcanvasElementList].map(offcanvasEl => new bootstrap.Offcanvas(offcanvasEl))
  }, []);


  const logout = () => {
    UserService.logout();
    navigate("/");
    window.location.reload(true);
  }

  const hideSideMenu = () => {
    const btnClose = document.querySelector('.btn-close');
    btnClose.click()
  }

  return (

    <nav className="navbar navbar-expand-lg fixed-top bg-light shadow-sm">
      <div className="container-fluid">
        <Link to="/">          
          <span className="navbar-brand" style={{ color: "#1976d2" }} >Bubeph</span>
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* Start Side menu on small devices */}
        <div className="offcanvas offcanvas-end d-lg-none d-xl-none d-xxl-none" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel" style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/">
                  <span className="dropdown-item" onClick={() => hideSideMenu()} >Home</span>
                </Link>
              </li>
            </ul>
            <span className="navbar-text">

              {
                user === null || user === undefined ?
                  <>
                    <button className="btn btn-primary btn-sm" onClick={() => hideSideMenu()}><Link to="/register" style={{ color: "white" }}>Create Account</Link></button>
                    <button className="btn btn-success btn-sm ml-3" onClick={() => hideSideMenu()}> <Link to="/login" style={{ color: "white" }}>Log in</Link></button>
                  </>
                  :
                  <ul>
                    <li style={{ cursor: "pointer" }} className='d-flex flex-row'>
                      <div className="dropdown">
                        <ul className="">
                          <li>
                            <Link to="/account/profile">
                              <span className="" onClick={() => hideSideMenu()}>Profile</span>
                            </Link>
                          </li>
                          <li className='mt-2'>
                            <Link to="/account/history">
                              <span className="" onClick={() => hideSideMenu()}>History</span>
                            </Link>
                          </li>
                          <li className='mt-2'>
                            <a className="" onClick={() => logout()} >Log out</a>
                          </li>
                        </ul>
                      </div>

                    </li>
                  </ul>

              }
            </span>
          </div>
        </div>
        {/* End Side menu on small devices */}

        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link to="/">
                <span className="dropdown-item" >Home</span>
              </Link>
            </li>
          </ul>
          <span className="navbar-text">
            {
              user === null || user === undefined ?
                <>
                  <button className="btn btn-primary btn-sm"><Link to="/register" style={{ color: "white" }}>Create Account</Link></button>
                  <button className="btn btn-success btn-sm ml-3"> <Link to="/login" style={{ color: "white" }}>Log in</Link></button>
                </>
                :
                <ul>
                  <li style={{ cursor: "pointer" }} className='d-flex flex-row'>

                    <div className="dropdown dropstart">
                      <img src="/images/user_1.png" className="dropdown-t" alt="" height="25px" width="25px" onClick={showDropdown} data-bs-toggle="dropdown" />
                      <ul className="dropdown-menu">
                        <li>
                          <Link to="/account/profile">
                            <span className="dropdown-item" >Profile</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/account/history">
                            <span className="dropdown-item" >History</span>
                          </Link>
                        </li>
                        <li><hr className="dropdown-divider" /></li>
                        <li>
                          <a className="dropdown-item" href="#" onClick={() => logout()}>Log out</a>
                        </li>
                      </ul>
                    </div>

                  </li>
                </ul>

            }
          </span>
        </div>
      </div>

    </nav>
  );
}

export default Header;