import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";
import { Link, Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom'


const AccountPage = () => {
    const location = useLocation();
    const [activePath, setActivePath] = useState("");
    useEffect(() => {
        //setActivePath()
        //function HeaderView() {
          const activePage = location.pathname.substring( location.pathname.lastIndexOf("/")+1 );
          setActivePath(activePage);
          //return <span>Path : {location.pathname}</span>
        //}
        
    }, [activePath]);

    return (
        <div className="container" style={{ "marginTop": "120px" }}>
            <div className='row d-flex justify-content-center' >
                <div className="col-lg-3 col-xl-2 col-md-4">
                    <div className="list-group side-menu shadow-sm">
                        {/* <a href="#" className="" aria-current="true">
                                Profile
                                </a> */}
                        <Link to="/account/profile">
                        {/* ${ active-menu} */}
                            <span 
                                className={`list-group-item list-group-item-action ${ activePath === 'profile' ? 'active-menu': '' }`}
                                onClick={() => setActivePath('profile')}
                            >
                                <i className="fa-solid fa-user mr-2"></i>
                                <span style={{ padding: "0px", color: "#1976d2", fontWeight:"500" }}> Profile </span>
                            </span>
                        </Link>
                        <Link to="/account/history">
                            <span 
                            className={`list-group-item list-group-item-action ${ activePath === 'history' ? 'active-menu': '' }`}
                            onClick={() => setActivePath('history')}>
                                <i className="fa-solid fa-clock-rotate-left mr-2"></i>
                                <span style={{ padding: "0px", color: "#1976d2", fontWeight:"500" }}> History </span>
                            </span>
                        </Link>

                        <Link to="/account/trackedProducts">
                            <span 
                            className={`list-group-item list-group-item-action ${ activePath === 'trackedProducts' ? 'active-menu': '' }`}
                            onClick={() => setActivePath('trackedProducts')}>
                                <i className="fa-solid fa-arrow-trend-up mr-2"></i>
                                <span style={{ padding: "0px", color: "#1976d2", fontWeight:"500" }}> Tracked products </span>
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-9 col-xl-9 col-md-12 mt-md-5 mt-sm-5 mt-5 mt-lg-0 mt-xl-0">
                    <Outlet />
                </div>

            </div>
        </div>
    )
}


export default AccountPage;