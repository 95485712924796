import React, { useEffect, useState } from "react";
import UserService from "../services/user.service";

const ProfilePage = () => {

    const [user, setUser] = useState(null);

    useEffect(() => {

        (async () => {
            const user = await UserService.getUser();
            setUser(user);
        })();

    }, []);


    return (
        <div>
            <h1 style={{ fontSize: "2.1rem", marginBottom: "30px", color: "#424242", fontWeight: "500" }}>Profile</h1>
            <form>
                <div className="row mb-4">
                    <div className="col-lg-5" style={{ "position": "relative" }}>
                        <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Username</h3>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="username"
                                name="username"
                                value={user != null && user.email}
                                placeholder="Username"
                                disabled
                            />
                        </div>
                        {/* <div >
                                    <button type="submit"
                                        className="btn btn-primary btn-sm"
                                        style={{ "backgroundColor": 'red !important' }}
                                    >Sign up</button>
                                </div> */}
                    </div>

                </div>

                <div className="row mb-4">
                    <div className="col-lg-5" style={{ "position": "relative" }}>
                        <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Contact Email</h3>
                        <h5 className="mb-3" style={{ "color": "#616161", "fontSize": "0.9rem", "fontWeight": "bold" }}>Manage your accounts email.</h5>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="email"
                                name="email"
                                value={user != null && user.email}
                                placeholder="Example@exmaple.com"
                                disabled
                            />
                        </div>
                    </div>

                </div>
                <div className="row  mb-4">

                    <h3 className="mb-2" style={{ "fontSize": "1.2rem", "fontWeight": "bold" }}>Password</h3>
                    <h5 className="mb-3" style={{ "color": "#616161", "fontSize": "0.9rem", "fontWeight": "bold" }}>Modify your current password..</h5>
                    <div className="col-lg-5" style={{ "position": "relative" }}>
                        <div className="mb-3">
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="form-control form-control-sm"
                                placeholder="New password"
                                disabled

                            />
                        </div>
                    </div>
                    <div className="col-lg-5" style={{ "position": "relative" }}>
                        <div className="mb-3">
                            <input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                className="form-control form-control-sm"
                                placeholder="Confirm password"
                                disabled
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div>
                        <button type="submit" disabled
                            className="btn btn-primary btn-sm"
                            style={{ "backgroundColor": 'red !important' }}
                        >Save change</button>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default ProfilePage;