import React, { useEffect } from 'react';
import * as bootstrap from 'bootstrap';
import Toast from './toast.component';
import toast from 'react-hot-toast';
import Stars from './stars.component';
import TrackingProductService from '../services/trackingProduct.service';

// const calculateStars = (stars) => {
//   const starSolid = Math.floor(stars);
//   const rest = stars - starSolid;
//   const starHalf = rest < 1 && rest > 0 ? 1 : 0;
//   const starRegular = Math.floor(5 - stars);
//   return {
//     "starSolid": starSolid,
//     "starHalf": starHalf,
//     "starRegular": starRegular
//   }
// }

// const Stars = ({ stars }) => {
//   const res = calculateStars(stars);
//   return <span>
//     {
//       [...Array(res.starSolid)].map((x, index) =>
//         <i className="fa-solid fa-star" key={index} style={{ "cursor": "pointer", color: "#fdd835" }}></i>
//       )
//     }
//     {
//       [...Array(res.starHalf)].map((x, index) =>
//         <i className="fa-solid fa-star-half-stroke" key={index} style={{ "cursor": "pointer", color: "#616161" }}></i>
//       )
//     }
//     {
//       [...Array(res.starRegular)].map((x, index) =>
//         <i className="fa-regular fa-star" key={index} style={{ "cursor": "pointer" }}></i>
//       )
//     }
//   </span>
// }


const Prodcut = ({ user, product }) => {

  const notify = (title, message, type) => {
    toast.custom(
      <Toast title={title} message={message} type={type} />,{
        duration: 4000,
      }
    );    
  }  

  const trackProduct = async (productId) => {
    TrackingProductService.trackProduct(productId)
      .then((response) => response.data)
      .then((data) => {
        notify("Tracking product", data.message, "WARNING");
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
  }, [])

  return (

    <>

      <div className='col-lg-8 col-xl-6 d-none d-sm-block' style={{ marginBottom: "17px" }}>
        <div className="row shadow-sm"
          style={{ "height": "190px", "border": "2px solid #eeeeee", "borderRadius": "5px", "padding": "10px 5px 10px 5px" }}>
          <div className='col-lg-3 col-sm-3' style={{ height: "100%" }}>
            <img
              src={product.urlImage}
              className="img-fluid"
              style={{ "width": "auto", "height": "100%", "borderRadius": "5px" }}
              alt="..." />
          </div>

          <div className='col-lg-7 col-sm-7'>
            <div className="d-flex flex-column justify-content-between" style={{ "height": "100%" }}>
              <div>
                <h3
                  className="truncate-multi-line"
                  style={{ "fontWeight": "bold", "cursor": "pointer", "fontSize": "1.1rem", }} >
                  {product.title}
                </h3>
                <div className="mt-2">
                  <span className="mr-2" style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    {product.stars}
                  </span>
                  <span> <Stars stars={product.stars} /></span>
                </div>
              </div>
              <div className='d-flex  justify-content-between'>
                <a href="#" style={{ "color": "#1976d2", "fontSize": "0.9rem", "fontWeight": "bold" }}>View Details</a>
                {user && <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  data-bs-title="Track product"
                  onClick={() => trackProduct(product.id)}>
                  <i className="fa-solid fa-binoculars" style={{ cursor: "pointer" }} ></i>
                </span>}
              </div>
            </div>
          </div>

          <div className='col-lg-2 col-sm-2'>
            <div className='d-flex flex-column justify-content-between' style={{ "height": "100%" }}>
              <div className="d-flex justify-content-end">
                <i className="fa-regular fa-heart" style={{ "cursor": "pointer" }}></i>
                {/* <i class="fa-solid fa-heart"></i> */}
              </div>
              <div className='d-flex justify-content-end'>
                {product.price != null
                  ? <span className="fs-5 fw-semibold" style={{ "color": "#757575" }}><span>$</span> {product.price} </span>
                  : <span className="fs-5 fw-semibold" style={{ "color": "#757575", fontSize: "0.3rem" }}>Price not available</span>}
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* Start display in xs devices */}
      <div className='col-xs-12 d-block d-sm-none' style={{ marginBottom: "17px" }}>
        <div className="row shadow-sm" style={{ "border": "2px solid #eeeeee", "borderRadius": "5px", "padding": "10px 5px 10px 5px" }}>
          <div className="d-flex justify-content-end">
            <i className="fa-regular fa-heart" style={{ "cursor": "pointer" }}></i>
          </div>
          <div className='col-xs-12 d-flex justify-content-center' >
            <img
              src={product.urlImage}
              className="img-fluid"
              style={{ "width": "auto", "height": "100%", "borderRadius": "5px" }}
              alt="..." />
          </div>

          <div className='col-xs-12'>
            <div className="d-flex flex-column justify-content-between" style={{ "height": "100%" }}>
              <div>
                <h3
                  className="truncate-multi-line"
                  style={{ "fontWeight": "bold", "cursor": "pointer", "fontSize": "1.1rem", }} >
                  {product.title}
                </h3>
                <div className="mt-3">
                  <span className="mr-2" style={{ fontSize: "0.9rem", fontWeight: "bold" }}>
                    {product.stars}
                  </span>
                  <span> <Stars stars={product.stars} /></span>
                </div>
              </div>
              <div className='d-flex justify-content-between mt-2'>
                <div className='d-flex'>
                  <div>
                    <a href="#" style={{ "color": "#1976d2", "fontSize": "0.9rem", "fontWeight": "bold" }}>View Details</a>
                  </div>
                  <div className='ml-3'>
                    {user && <span
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-title="Track product"
                      onClick={() => trackProduct(product.id)}>
                      <i className="fa-solid fa-binoculars" style={{ cursor: "pointer" }} ></i>
                    </span>}
                  </div>
                </div>
                <div>
                  {product.price != null
                    ? <span className="fs-5 fw-semibold" style={{ "color": "#757575" }}><span>$</span> {product.price} </span>
                    : <span className="fs-5 fw-semibold" style={{ "color": "#757575", fontSize: "0.3rem" }}>Price not available</span>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* End display in xs devices */}

    </>

  )

}

export default Prodcut;