import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SearchService from '../services/search.servie';
import { useSearchParams } from 'react-router-dom';
import UserService from '../services/user.service';
import VisitorService from '../services/visitor.service';



const Search = ({ onChangeKeyword, loader }) => {

    const visitor = VisitorService.getCurrentVisitor();

    const [query, setQuery] = useState("");
    const [test, setTest] = useState(null);    
    const [isDisabled, setIsDisabled] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleClick = () => { loader(true); }

    const formik = useFormik({
        initialValues: {
            query: ""
        },
        validationSchema: Yup.object({
            query: Yup.string().required('Required')
        }),

        onSubmit: async (values) => {
            if (formik.isValid) {
                try {
                    // check if user authenticated 
                    if (!UserService.isTokenExpired()) {
                        // request search 
                        setIsDisabled(true);
                        const searchRespoonse = await SearchService.search(values.query);     
                        if (searchRespoonse.data != null) setIsDisabled(false);
                        onChangeKeyword(searchRespoonse);
                    }
                    else if (visitor != null && visitor != undefined) {
                        // request searchForGuest
                        setIsDisabled(true);
                        const searchRespoonse = await SearchService.searchForGuest(visitor.visitorId, values.query);                        
                        if (searchRespoonse.data != null) setIsDisabled(false);
                        onChangeKeyword(searchRespoonse);
                    } else {
                        // handle this case
                    }

                    // setTimeout(() => {                        
                    //     //loader(true);
                    // },1000);


                    // if(userRespoonse.data != null){
                    //     localStorage.setItem("token", JSON.stringify(userRespoonse.data));
                    // }
                    // navigate("/search");
                } catch (errorResponse) {
                    // console.log(errorResponse)
                    // if(errorResponse.response.status === 403){
                    //     // set message
                    //     showToast();
                    // }
                }
                // store token, in local storage; 
                // handle 403 
                // redirect to home page 

            }
        },
    });

    useEffect(() => {            
        (async () => { 
            try {
                const queryParam = searchParams.get('query');
                if (queryParam != "" && queryParam != null) {
                    formik.setFieldValue('query', queryParam);
                    setIsDisabled(true);                    
                    if (!UserService.isTokenExpired()) {
                        // request search 
                        const searchRespoonse = await SearchService.search(queryParam);
                        onChangeKeyword(searchRespoonse);
                    }
                    else if (visitor != null && visitor != undefined) {
                        // request searchForGuest
                        const searchRespoonse = await SearchService.searchForGuest(visitor.visitorId, queryParam);
                        //console.log(searchRespoonse);
                        onChangeKeyword(searchRespoonse);
                    } else {
                        // handle this case
                    }                    
                    setIsDisabled(false);
                }
            } catch (errorResponse) {
                setIsDisabled(false);
            }
        })();

    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className="">
            {/* "position": "relative" */}
            <div className='row'>
                <div className="col-lg-8 col-md-9 col-sm-10 col-9" style={{}}>
                    <div className="input-group input-group-sm">
                        <span className="input-group-text" id="basic-addon1" style={{ "backgroundColor": "white" }}>
                            <i className="fa-solid fa-magnifying-glass"></i>
                            {/* <BeakerIcon className="h-5 w-5 text-blue-500" /> */}
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="What are you looking for?"
                            aria-label="query"
                            id="query"
                            name="query"
                            style={{ "borderLeft": "none" }}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.query}
                        />
                    </div>

                    <div style={{ "position": "absolute", "left": "0", "padding": "0 8px", "width": "100%" }} className='mt-2 sugestion-search sugestion-search-toggle'>
                        <div className='container-sugestion-search'>
                            <ul>
                                <li> <span>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quam </span> </li>
                                <li> <span>  molestias, corporis, optio ipsam minus quo sint velit cupiditate </span> </li>
                                <li> <span> ad qui veniam illum ipsum aliquid voluptas excepturi dolore </span> </li>
                                <li> <span> temporibus. Repellat, assumenda!</span> </li>
                                <li> <span> temporibus. Repellat, assumenda!</span> </li>
                                <li> <span> temporibus. Repellat, assumenda!</span> </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-3 col-sm-2 col-2">
                    <button
                        disabled={isDisabled}
                        type="submit"
                        onClick={handleClick}
                        className="btn btn-primary btn-sm"
                        style={{ "backgroundColor": 'red !important' }}>
                        search
                    </button>                 
                </div>
            </div>
        </form>


    );
}

export default Search;